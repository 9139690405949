<template>
  <div class="a-container">
    <div id="a-login">
      <div id="a-form">
        <img
          id="a-form-habitat"
          :src="require('@/assets/images/logo/induccion.svg')"
          style="margin: 20px 0; height: 100px"
        />
        <div>
          <h4>Iniciar sesión</h4>
          <span class="a-input-icon">
            <img :src="require('@/assets/icons/user.svg')" />
            <InputText
              type="text"
              v-model="form.username"
              placeholder="Usuario"
            />
          </span>
          <span class="a-input-icon">
            <img :src="require('@/assets/icons/lock.svg')" />
            <InputText
              type="password"
              v-model="form.password"
              placeholder="Contraseña"
            />
          </span>
          <primary-button
            :title="'Iniciar sesión'"
            :isValid="isValid"
            :isLoading="isLoading"
            @onClick="login"
          />
        </div>
      </div>
      <div id="a-login-img">
        <img :src="require('@/assets/images/auth/login.png')" />
      </div>
    </div>

    <img
      :src="require('@/assets/images/logo/villavo-white.svg')"
      style="margin-top: 20px; height: 48px"
    />
  </div>
  <Toast />
</template>

<script>
import PrimaryButton from "../components/widgets/PrimaryButton.vue";
export default {
  components: { PrimaryButton },
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      isLoading: false,
      isChangeVisible: false,
    };
  },
  computed: {
    isValid() {
      return this.form.username !== "" && this.form.password !== "";
    },
  },
  methods: {
    login() {
      this.isLoading = true;
      this.$store
        .dispatch("auth/LOGIN", this.form)
        .then((response) => {
          this.$router.push({ name: response });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Ha ocurrido un error",
            detail: error,
            life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
@media (max-width: 954px) {
  #a-login {
    flex-flow: column-reverse !important;
    max-width: 80% !important;
    width: 80% !important;
    height: 70% !important;
  }

  #a-login-img {
    max-width: 100% !important ;
    min-width: 100% !important;
    width: 100% !important;
    height: 200px !important;
  }
}

@media (max-width: 480px) {
  #a-form h4 {
    font-size: 16px !important;
    margin: 10px;
  }
  #a-form #a-form-habitat {
    height: 60px !important;
  }
}
</style>

<style scoped>
.a-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--color-1);
}

#a-login {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  border: none;
  border-radius: 20px;
  max-width: 70%;
  min-height: 50%;
  width: 80%;
  height: 60%;
}

#a-login-img {
  max-width: 50%;
  min-width: 50%;
  width: 50%;
  height: 100%;
  position: relative;
}

#a-login-img:after {
  border-radius: 20px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(0, 217, 134, 0.2) 50%,
    rgba(0, 217, 134, 0.7) 100%
  );
}

#a-login-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  height: inherit !important;
}

#a-form {
  margin: 0 40px;
  padding: 20px;
  width: 100%;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}
#a-form div {
  width: 100%;
  text-align: left;
}
#a-form h4 {
  color: #333333;
  font-family: "Quicksand", sans-serif !important;
  font-size: 24px;
}
</style>
